import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const cityId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			city_name:'',			
			city_image: '',
			address: '',
			priority: '',
			lat:'',		    
			lang:'',		    
			status:'',		    
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			city_id: cityId,
			Loading:false,
			old_image:'',
			image_preview:'',
			image_name:'',
			image:'',
		};
		

		axios.get(apiUrl+"popularcity/listdetail?id="+this.state.city_id).then(res => {
			if(res.data.status === "success"){

				const formdata = res.data.citylist;
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}				
				this.setState({city_name:formdata.cityName});							
				//this.setState({discount_type: formdata.discount_type});
				this.setState({address: formdata.address});
				this.setState({priority: formdata.priority});
				this.setState({lat: formdata.lat});
				this.setState({lang: formdata.lang});
				this.setState({editid:1,image_preview:formdata.image,old_image:formdata.image})
			}
		}); 

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.handleChange2 = this.handleChange2.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChange2 = discountType => {
		this.setState({discountType})
		this.setState({  discountSel : discountType.value});
	};
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }
	onFileChange = (e) =>{
		this.fileObj = [];
		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i])
			this.imageArray.push(this.fileObj[0][i])

		}
		this.setState({ city_image: this.imageArray })		
	}

	componentDidMount() {
		document.title = PageTitle('Airport Edit');
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				// let formData = new FormData();
				// for(let k in postObject) {
				// formData.append(k, postObject[k]);
				// }
				// var filesdata = this.state.city_image;
				// for (var i in filesdata) {
				//  formData.append('city_image[]',filesdata[i])
				// }	
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					city_id : formPayload.city_id,
					city_name    : formPayload.city_name,
					address    : formPayload.address,
					lat    : formPayload.lat,
					lang    : formPayload.lang,
					priority : formPayload.priority,	
					status       : status,
					editid:  this.state.editid,
					old_image:  this.state.old_image,
					image: this.state.editid===0 ? formPayload.image:'',
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		
				axios.post(apiUrl+"popularcity/edit",formData).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/popularcity');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {city_name,address,priority} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		  if (!city_name) {
			errors++;
			$('.errorcity_name').html('<span class="errorspan">Please enter the name</span>');
		}else if(city_name){
			$('.errorfont_name').html('');
		}
		
		// if (!discount_type) {
		// 	errors++;
		// 	$('.errordiscount_type').html('<span class="errorspan">Please select discount type</span>');
		// }else if(discount_type){
		// 	$('.errordiscount_type').html('');
		// }
		if (!address) {
			errors++;
			$('.erroraddress').html('<span class="errorspan">Please enter address</span>');
		}else if(address){
			$('.erroraddress').html('');
		}
		if (!priority) {
			errors++;
			$('.errorpriority').html('<span class="errorspan">Please enter priority</span>');
		}else if(priority){
			$('.errorpriority').html('');
		}
		// if (!startDate) {
		// 	errors++;
		// 	$('.errorstartDate').html('<span class="errorspan">Please select start date</span>');
		// }else if(startDate){
		// 	$('.errorstartDate').html('');
		// }
		// if (!endDate) {
		// 	errors++;
		// 	$('.errorendDate').html('<span class="errorspan">Please select end date</span>');
		// }else if(endDate){
		// 	$('.errorendDate').html('');
		// }

		if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
	handleInputOnchangeAddress = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	
		const google = window.google;
		const input = document.getElementById('address');
	
		const autocomplete = new google.maps.places.Autocomplete(input);
	
		autocomplete.addListener('place_changed', () => {
			//document.getElementById('location-error').style.display = 'none';
			const place = autocomplete.getPlace();
	
			// Extracting street, city, state, and country
			const street = place.name; // You may want to use a more specific property for street
			// const cityComponent = place.address_components.find(
			// 	(component) => component.types.includes('locality')
			// );
			// const city = cityComponent ? cityComponent.long_name : '';
	
			// const stateComponent = place.address_components.find(
			// 	(component) => component.types.includes('administrative_area_level_1')
			// );
			// const state = stateComponent ? stateComponent.short_name : '';
	
			// const countryComponent = place.address_components.find(
			// 	(component) => component.types.includes('country')
			// );
			// const country = countryComponent ? countryComponent.long_name : '';
			// const zipCodeComponent = place.address_components.find(
			// 	(component) => component.types.includes('postal_code')
			// );
			// const zipCode = zipCodeComponent ? zipCodeComponent.short_name : '';
			const locationAddress = place.formatted_address;
			const lat = place.geometry.location.lat();
			const lang = place.geometry.location.lng();
			// const { lat, lng } = place.geometry.location;
			//console.log(lat, lng, 'Current city coordinates');
			const fullplace = street + ',' + locationAddress;
			console.log(lat, lang, "--lat,lang");
			if (input.value !== '') {
				this.setState(
					(prevState) => ({
						fullpickuploc: fullplace,
						address: locationAddress,						
						lat: lat,						
						lang: lang,						
						// street: street,
						// city: city,
						// state: state,
						// zipcode: zipCode,
						
					}),
					() => {
						// Additional logic after setting the state
					}
				);
			}
		});
	
		if (input.value === '') {
			this.setState({ address: '',driver_address:'', street: '',city:'', state:'', zipcode:''  });
		}
	};

  
  render() {
  	  const {selectedOption, discountType} = this.state;
		if(this.state.editid==1){
			console.log(1)
			var loaderimg = mediaUrl+'/popularcity/' +this.state.image_preview;
			var preview = <img className="img_class" src={loaderimg} alt="" />;
		}else {
			console.log(2) 
			var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
		}
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="popularcity" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/popularcity">Back</a>
			</div>
			<div className="title">
				<h4>Airport Details</h4>
			</div>
			 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="city_name" onChange={this.handleInputChange} className="form-control" value={this.state.city_name} autoComplete="off" />
					<div className="errorcity_name"></div>
				</div>			
				<div className="form-group">
					<label>Address</label>					
					<input type="text" name="address" id="address"
					//onChange={this.handleInputChange} 
					onChange={this.handleInputOnchangeAddress} 
					className="form-control" value={this.state.address} autoComplete="off" />
					<div className="erroraddress"></div>
				</div>
				<div className="form-group">
					<label>Priority</label>
					<input type="text" name="priority" onChange={this.handleInputChange} className="form-control" value={this.state.priority} autoComplete="off" />
					<div className="errorpriority"></div>
				</div>				
			</div>				
			<div className="form-right">

			<div className="form-group" >
				<label>Icon</label>
				<input type="file" name="file"  className="form-control" onChange={this.onChangeHandler} /> 
				{preview}
				<div className="errorfile"></div> 
			</div>		
			<div className="form-group">					
				<label>Status</label>
				<Select 
				value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
				options={lang.common.status_option} 
				onChange={this.handleChange}
				/>
			</div>

			</div>	
			</div>	
			
						

		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Edit);